import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"


const Wrapper = styled.article`
  border-radius: 5px;
  background: ${({ theme }) => theme.background.paper};
  ${({ theme }) => theme.dp.one};
  :hover {
    ${({ theme }) => theme.dp.eight};
    /* transform: translateY(-2px); */
    transition: .5s;
  }
  a {
    text-decoration: none;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

const Content = styled.div`
  img {
    width: 100%;
    height: auto;
    border-radius: 5px 5px 0 0;
  }
`

const CategoryImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  height: 50px;
  width: 50px;
  margin: -25px 15px auto auto;
  border-radius: 50%;
  font-size: 15px;
  background: ${({ theme }) => theme.postCard.categoryImageBackground};
  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    img {
      width: 30px;
      height: auto;
    }
  }
`

const CardInfo = styled.ul`
  display: grid;
  grid-gap: 7.5px;
  padding: 5px 30px 30px;
  li {
    display: inline-block;
    .dateTime {
      display: inline-flex;
      text-align: center;
      font-size: 16px;
      white-space: nowrap;
      color: ${({ theme }) => theme.text.secondary};
      svg {
        width: 14px;
        height: 14px;
        fill: ${({ theme }) => theme.text.secondary};
        vertical-align: bottom;
      }
    }
    .title {
      color: ${({ theme }) => theme.text.primary};
      font-size: 19px;
      line-height: 19px;
    }
  }
`

const PostCard = ({ title, category, categoryUrl, date, update, url, hero }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {regex: "/categories/"}}) {
        nodes {
          publicURL
          name
        }
      }
    }
  `)
  const categoryImg = data.allFile.nodes.find((i) => { return i.name === category })

  const theme = typeof window === 'undefined' ? "light" : window.localStorage.getItem("theme")

  const dayStr = update === null ?
    date.replace("-", "年").replace("-", "月") + "日" :
    update.replace("-", "年").replace("-", "月") + "日"
  
  return (
    <Wrapper>
      <Content>
        <Link
          to={url}
          aria-label={`${title}-image`}
        >
          {hero === null
            ?
            <img
              width="1350"
              height="650"
              src={`https://res.cloudinary.com/dqi4uoycj/image/upload/l_text:Sawarabi%20Gothic_50:${title},w_600,c_fit,q_auto,f_auto,${theme === "light"
                ? `co_rgb:333/v1609251859/light_post.png`
                : `co_rgb:FFF/v1609251867/dark_post.png`}`}
              alt={title}
            />
            :
            <Img
              fadeIn={false}
              loading={"eager"}
              fluid={hero}
              alt={title}
            />
          }
        </Link>
        <CategoryImageWrapper>
          <Link
            to={categoryUrl}
            aria-label={category}
          >
            <img
              width="30" height="30"
              src={categoryImg.publicURL}
              alt={category}
              aria-label={category}
            />
          </Link>
        </CategoryImageWrapper>
        <Link
          to={url}
          aria-label={title}
        >
          <CardInfo>
            {/* 日付 */}
            {update === null ?
              <li>
                <time dateTime={date}>
                  <span className="dateTime" role="img" aria-label="published_at">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z" />
                    </svg>
                  :{dayStr}
                  </span>
                </time>
              </li>
              :
              <li>
                <time dateTime={update}>
                  <span className="dateTime" role="img" aria-label="update">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
                      <g>
                        <rect fill="none" height="24" width="24" x="0" />
                      </g>
                      <g><g><g>
                        <path d="M21,10.12h-6.78l2.74-2.82c-2.73-2.7-7.15-2.8-9.88-0.1c-2.73,2.71-2.73,7.08,0,9.79s7.15,2.71,9.88,0 C18.32,15.65,19,14.08,19,12.1h2c0,1.98-0.88,4.55-2.64,6.29c-3.51,3.48-9.21,3.48-12.72,0c-3.5-3.47-3.53-9.11-0.02-12.58 s9.14-3.47,12.65,0L21,3V10.12z M12.5,8v4.25l3.5,2.08l-0.72,1.21L11,13V8H12.5z" />
                      </g></g></g>
                    </svg>
                  :{dayStr}
                  </span>
                </time>
              </li>
            }
            {/* タイトル */}
            <li><span className="title">{title}</span></li>
          </CardInfo>
        </Link>
      </Content>
    </Wrapper>
  )
}

export default PostCard
